export class locales {
  public static readonly localesList: Locale[] = [
    {
      code: 'en',
      label: 'English',
    },
    {
      code: 'sv',
      label: 'Swedish',
    },
  ];
  public static readonly defaultLanguge: Locale = {
    code: 'sv',
    label: 'Swedish',
  };
}

export interface Locale {
  code: string;
  label: string;
}
