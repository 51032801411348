import { Injectable } from '@angular/core';
import {
  ProblemDetails,
  ValidationProblemDetails,
} from '@tfagency/authority/data-access';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private notFoundError: ReplaySubject<ProblemDetails> = new ReplaySubject(0);
  public notFoundError$ = this.notFoundError.asObservable();

  private validationError: ReplaySubject<ValidationProblemDetails> =
    new ReplaySubject(0);
  public validationError$ = this.validationError.asObservable();

  private internalServerError: ReplaySubject<ProblemDetails> =
    new ReplaySubject(0);
  public internalServerError$ = this.internalServerError.asObservable();

  private notReachableError: ReplaySubject<any> = new ReplaySubject(0);
  public notReachableError$ = this.notReachableError.asObservable();

  public publishError(httpError: any): void {
    switch (httpError.status) {
      case 404: {
        this.notFoundError.next(httpError.detail as ProblemDetails);
        break;
      }
      case 400: {
        this.validationError.next(httpError.error as ValidationProblemDetails);
        break;
      }
      case 500: {
        this.internalServerError.next(httpError.title as ProblemDetails);
        break;
      }
      default: {
        let serverOfflineError = '';
        if (!localStorage.getItem('languageCode')) {
          this.notReachableError.next(
            'Anslutningen till servern bröts, var vänlig försök igen'
          );
          return;
        }

        if ((localStorage.getItem('languageCode') as string) === 'sv') {
          serverOfflineError =
            'Anslutningen till servern bröts, var vänlig försök igen';
        } else {
          serverOfflineError = 'Server is not reachable, please try again';
        }
        this.notReachableError.next(serverOfflineError);
        break;
      }
    }
  }
}
