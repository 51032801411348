import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from './componentBase';
import { ErrorService } from '../services/error.service';

@Component({
  template: '',
})
export abstract class FormBaseComponent<
    TData extends {
      [K in keyof TData]: AbstractControl<any>;
    } = any
  >
  extends BaseComponent
  implements OnInit, OnDestroy
{
  showValidation = false;
  form!: FormGroup<TData>;

  constructor(protected override errorService: ErrorService) {
    super(errorService);
  }

  public getName(control: AbstractControl): string | null {
    const group = control?.parent as UntypedFormGroup;
    if (!group) {
      return null;
    }
    let name = '';
    Object.keys(group.controls).forEach((key) => {
      const childControl = group.get(key);
      if (childControl !== control) {
        return;
      }
      name = key;
    });
    return name;
  }

  protected mapControlsToModel<TCommand extends object>() {
    return this.form.value as TCommand;
  }

  protected checkFormValidity() {
    if (this.form.invalid) {
      this.showValidation = true;
      this.form.markAllAsTouched();
      return true;
    }
    return false;
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
