import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localDateTimePipe',
})
export class LocalDateTimePipe implements PipeTransform {
  transform(date: Date) {
    return moment.utc(date).local().toDate();
  }
}
