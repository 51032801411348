import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink/dist/subsink';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BehaviorSubject } from 'rxjs';
import { RoleType } from '@tfagency/core/data-access';
import { ErrorService } from '../services/error.service';

@Component({
  template: '',
})
export class BaseComponent implements OnInit, OnDestroy {
  private isErrorThrown = new BehaviorSubject<boolean>(false);
  isErrorThrown$ = this.isErrorThrown.asObservable();
  protected subs = new SubSink();
  errorMessage!: any;
  isWaiting = false;
  fullName!: string;
  role!: RoleType;

  constructor(protected errorService: ErrorService) {}

  ngOnInit() {
    this.subs.sink = this.errorService.notFoundError$.subscribe(
      (problemDetails) => {
        this.isWaiting = false;
        this.errorMessage = problemDetails;
        this.isErrorThrown.next(true);
      }
    );

    this.subs.sink = this.errorService.internalServerError$.subscribe(
      (problemDetails) => {
        this.isWaiting = false;
        this.errorMessage = problemDetails;
        this.isErrorThrown.next(true);
      }
    );

    this.subs.sink = this.errorService.notReachableError$.subscribe(
      (errorMessage) => {
        this.isWaiting = false;
        this.errorMessage = errorMessage;
        this.isErrorThrown.next(true);
      }
    );

    this.subs.sink = this.errorService.validationError$.subscribe(
      (validationProblemDetails) => {
        this.isWaiting = false;
        if (!validationProblemDetails['errors']) {
          return;
        }
        this.errorMessage = validationProblemDetails['errors'];
        this.isErrorThrown.next(true); // TODO: correct and turn on validationProblemDetails?.errors['']?.join(',');
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
