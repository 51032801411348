import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ErrorService, FormBaseComponent } from '@tfagency/shared/util';

@Component({
  selector: 'tfagency-account-created',
  templateUrl: './account-created.component.html',
  styleUrls: ['./account-created.component.scss'],
})
export class AccountCreatedComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  @Input() email!: string;
  @Input() returnUrl!: string;

  constructor(protected override errorService: ErrorService) {
    super(errorService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  submit(): void | Promise<void> {
    if (this.returnUrl) {
      location.href = this.returnUrl;
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onEnterHandler(): void {
    this.submit();
  }
}
