import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AssignCommand,
  AssignCommandResult,
  EstateDetailsModel,
  EstatesService,
  HouseCooperativeType,
  OwnershipType,
  RoleType,
  UpdateEstateCommand,
  User,
  UserModel,
  UsersService,
  ValuationPeriodEnum,
} from '@tfagency/core/data-access';
import { forkJoin, lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import { CustomValidators } from '../custom.validators';

@Injectable()
export class AssignPropertyService {
  seller!: User;
  brokers!: User[];
  propertyType!: 'House' | 'Apartment' | 'Townhouse';
  ownershipTypes = OwnershipType;
  isVerified = false;
  form = new UntypedFormGroup({});

  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private estatesService: EstatesService
  ) {}

  loadForm(
    estate: EstateDetailsModel,
    propertyType: 'House' | 'Apartment' | 'Townhouse'
  ): UntypedFormGroup {
    this.form = this.fb.group({
      street: [estate?.address?.street, [Validators.required]],
      city: [estate?.address?.city, [Validators.required]],
      municipality: [estate?.address?.municipality, [Validators.required]],
      postalCode: [estate?.address?.postalCode, [Validators.required]],
      brokerAgentUid: [null, [Validators.required]],
      secondaryBrokerAgentUid: [null],
      noteToBroker: [null, [Validators.required]],
      roomCount: [estate?.roomCount, [Validators.required]],
      livingArea: [estate?.livingArea, [Validators.required]],
    });
    switch (propertyType) {
      case 'House':
        this.addHouseFormControls(estate);
        this.patchHouseFormControls(estate);
        break;
      case 'Townhouse':
        this.addHouseFormControls(estate);
        this.patchTownhouseFormControls(estate);
        break;
      case 'Apartment':
        this.addApartmentFormControls(estate);
        break;
    }
    if (estate.ownershipType === OwnershipType.HouseCooperative) {
      this.addHouseCooperativeFormControls(estate);
    }
    return this.form;
  }

  private patchHouseFormControls(estate: EstateDetailsModel) {
    this.form.patchValue({
      plotArea: estate?.plotArea,
      otherArea: estate?.otherArea,
    });
  }

  private patchTownhouseFormControls(estate: EstateDetailsModel) {
    this.form.patchValue({
      plotArea: estate?.plotArea,
      otherArea: estate?.otherArea,
    });
  }

  private addApartmentFormControls(estate: EstateDetailsModel) {
    this.form.addControl(
      'floorsData',
      this.fb.group(
        {
          floor: [estate?.floor, [Validators.required]],
          totalNumberFloors: [estate?.totalNumberFloors, [Validators.required]],
        },
        { validators: CustomValidators.floorNumbers }
      ) as UntypedFormGroup
    );
    this.form.addControl('hasElevator', this.fb.control(estate?.hasElevator));
    this.form.addControl('hasBalcony', this.fb.control(estate?.hasBalcony));
    this.form.addControl('hasFireplace', this.fb.control(estate?.hasFireplace));
    this.form.addControl('hasPatio', this.fb.control(estate?.hasPatio));
  }

  private addHouseFormControls(estate: EstateDetailsModel) {
    this.form.addControl(
      'plotArea',
      this.fb.control(estate?.plotArea, Validators.required)
    );
    this.form.addControl(
      'otherArea',
      this.fb.control(estate?.otherArea, Validators.required)
    );
  }

  private addHouseCooperativeFormControls(estate: EstateDetailsModel) {
    this.form.addControl('monthlyFee', this.fb.control(estate?.monthlyFee));
  }

  getPropertyType(
    estate: EstateDetailsModel
  ): 'House' | 'Apartment' | 'Townhouse' {
    const propertyType =
      estate.ownershipType === OwnershipType.House
        ? 'House'
        : estate.houseCooperativeType === HouseCooperativeType.Apartment
        ? 'Apartment'
        : 'Townhouse';
    return propertyType;
  }

  getBrokers() {
    return forkJoin([
      this.usersService
        .usersGet(RoleType.BrokerAgent)
        .pipe(map((x) => x.users)) as Observable<UserModel[]>,
      this.usersService
        .usersGet(RoleType.Advisor)
        .pipe(map((x) => x.users)) as Observable<UserModel[]>,
    ]).pipe(
      map(([brokerAgents, advisors]) => {
        return [...brokerAgents, ...advisors];
      }),
      shareReplay()
    );
  }

  async deleteEstate(estateUid: string) {
    await lastValueFrom(
      this.estatesService.estatesUidDelete(estateUid, {
        estateUid: estateUid,
      })
    );
  }

  async assignEstate(
    estate: EstateDetailsModel,
    uniqueSellingPoints: string[],
    form: UntypedFormGroup
  ): Promise<AssignCommandResult> {
    const updateEstateCommand: UpdateEstateCommand = {
      isDraft: false,
      estate: {
        address: {
          street: form.value.street,
          city: form.value.city,
          municipality: form.value.municipality,
          postalCode: form.value.postalCode,
          crmStreet: form.value.street,
        },
        livingArea: form.value.livingArea,
        roomCount: form.value.roomCount,
        plotArea: form.value?.plotArea,
        otherArea: form.value?.otherArea,
        monthlyFee: form.value?.monthlyFee,
        uniqueSellingPoints: uniqueSellingPoints,
        hasBalcony: form.value?.hasBalcony,
        hasElevator: form.value?.hasElevator,
        hasFireplace: form.value?.hasFireplace,
        hasPatio: form.value?.hasPatio,
        floor: (<UntypedFormGroup>form.controls['floorsData'])?.controls[
          'floor'
        ]?.value,
        totalFloorCount: (<UntypedFormGroup>form.controls['floorsData'])
          ?.controls['totalNumberFloors']?.value,
        isShowedBrokerModal: false,
        isVerified: true,
        isPublishedOnline: false,
        valuationPeriod: ValuationPeriodEnum.Monthly,
        ownershipType: estate.ownershipType,
        houseType: estate?.houseType,
        houseCooperativeType: estate?.houseCooperativeType,
        uid: estate.uid,
      },
    };
    await lastValueFrom(
      this.estatesService.estatesUidPut(
        estate.uid as string,
        updateEstateCommand
      )
    );
    const assignEstateCommand: AssignCommand = {
      primaryBrokerAgentUserUid: form.value.brokerAgentUid,
      secondaryBrokerAgentUserUid: form.value?.secondaryBrokerAgentUid,
      noteToBroker: form.value.noteToBroker,
      estateUid: estate?.uid,
      isPublishedOnline: false,
      monthlyFee: form.value?.monthlyFee,
    };
    return (await lastValueFrom(
      this.estatesService.estatesUidAssignPost(
        estate?.uid as string,
        assignEstateCommand
      )
    )) as AssignCommandResult;
  }
}
