<div fxLayout="column">
  <h4>{{ 'Welcome' | translate }} {{ email }}.</h4>
  <h4>
    {{
      "You have successfully created your account, please press 'Ok' to continue"
        | translate
    }}.
  </h4>
  <button
    mat-raised-button
    [disabled]="isWaiting"
    color="primary"
    (click)="submit()"
    class="secondary-button"
  >
    {{ 'Ok' | translate }}
  </button>
</div>
