import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
@Pipe({
  name: 'enumTranslating',
})
export class EnumTranslatingPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  async transform(formattingEnum: string, language?: string): Promise<string> {
    return await lastValueFrom(this.translateService.get(formattingEnum));
  }
}
