export * from './mobile.service';
import { MobileService } from './mobile.service';
export * from './multiFactor.service';
import { MultiFactorService } from './multiFactor.service';
export * from './password.service';
import { PasswordService } from './password.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './web.service';
import { WebService } from './web.service';
export const APIS = [MobileService, MultiFactorService, PasswordService, UsersService, WebService];
