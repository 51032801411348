import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locales } from '@tfagency/shared/util';

@Component({
  selector: 'tfagency-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  localesList = locales.localesList;

  constructor(private translateService: TranslateService) {
    localStorage.getItem('language')
      ? this.translateService.use(localStorage.getItem('language') as string)
      : this.translateService.use(locales.defaultLanguge.code);
  }
}
