import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, from, Observable, retry, tap, throwError } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { ErrorService } from '../services/error.service';
import { StorageKeyConstants } from '../constants/storage-key.constants';

@Injectable()
export class ServerHttpInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private oauthService: OAuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorForDisplay: any;
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorForDisplay = `Error: ${error.error.message}`;
        } else {
          switch (error.status) {
            case 401:
              from(this.oauthService.revokeTokenAndLogout())
                .pipe(
                  tap(() => {
                    localStorage.removeItem(StorageKeyConstants.FILTERS);
                    localStorage.removeItem(StorageKeyConstants.PAGE_NUMBER);
                    localStorage.removeItem(StorageKeyConstants.PAGE_SIZE);
                    localStorage.removeItem(StorageKeyConstants.IS_NEW);
                    localStorage.removeItem(
                      StorageKeyConstants.IS_PENDING_VALUATION
                    );
                    localStorage.removeItem(
                      StorageKeyConstants.IS_CHAT_PENDING
                    );
                  })
                )
                .subscribe();
              break;
            case 400:
              {
                const listOfErrors = [];
                for (const key in error.error.errors) {
                  listOfErrors.push(error.error.errors[key]);
                }
                errorForDisplay = {
                  error: {
                    errors: listOfErrors.join(','),
                  },
                  status: 400,
                };
              }
              break;
            case 404:
              {
                errorForDisplay = error.error ?? error.message;
              }
              break;
            default: {
              errorForDisplay = error.error ?? error.message;
              break;
            }
          }
          this.errorService.publishError(errorForDisplay);
        }
        return throwError(() => error);
      })
    );
  }
}
