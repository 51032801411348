import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountCreatedComponent } from './two-factor-authentication/account-created/account-created.component';

const routes: Routes = [
  { path: 'account-created', component: AccountCreatedComponent },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'sign-out',
    loadChildren: () =>
      import('./sign-out/sign-out.module').then((m) => m.SignOutModule),
  },
  {
    path: 'sign-in-mobile',
    loadChildren: () =>
      import('./sign-in-mobile/sign-in-mobile.module').then(
        (m) => m.SignInMobileModule
      ),
  },
  {
    path: 'sign-up-portal',
    loadChildren: () =>
      import('./sign-up-portal/sign-up-portal.module').then(
        (m) => m.SignUpPortalModule
      ),
  },
  {
    path: 'sign-up-mobile',
    loadChildren: () =>
      import('./sign-up-mobile/sign-up-mobile.module').then(
        (m) => m.SignUpMobileModule
      ),
  },
  {
    path: 'sign-in-portal',
    loadChildren: () =>
      import('./sign-in-portal/sign-in-portal.module').then(
        (m) => m.SignInPortalModule
      ),
  },
  {
    path: 'two-factor-authentication',
    loadChildren: () =>
      import(
        './two-factor-authentication/two-factor-authentication.module'
      ).then((m) => m.TwoFactorAuthenticationModule),
  },
  {
    path: 'sign-in-complete',
    loadChildren: () =>
      import('./sign-in-complete/sign-in-complete.module').then(
        (m) => m.SignInCompleteModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
